import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ReviewsPageContainer = styled.div`
  height: 100dvh;
  width: 100dvw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
`;

export const ReviewForm = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  input {
    font-family: 'Poppins', sans-serif;
  }

  .stars {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .pulse-star {
    animation: pulse 1s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const SessionDetailDiv = styled.div`
  margin-bottom: ${({ margin }) => (margin ? 40 : 0)};
  transition: margin 0.3s;

  h2 {
    font-size: 20px;
    text-align: center;
  }
`;

export const OtherReviews = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  textarea {
    height: 90px;
    padding: 16px;
    resize: none;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    background: #fff;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    color: #3d3d3d;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    height: 40px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    background: #fff;
  }
`;
