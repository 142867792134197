import React, { useEffect, useState } from 'react';
import { DefaultInput } from 'ui-kit-takeat';
import { ErrorSpan, InputContainer, SvgContainer } from './styles';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const CustomInput = ({ ...rest }) => {
  const { type, error } = rest;
  const [hideText, setHideText] = useState(type === 'password');

  const typeHandling =
    type === 'password' ? (hideText ? 'password' : 'text') : type;

  return (
    <InputContainer>
      <DefaultInput
        {...rest}
        type={typeHandling}
        style={{
          borderRadius: 10,
          color: '#6c6c6c',
        }}
      />
      {type === 'password' && (
        <SvgContainer onClick={() => setHideText(state => !state)}>
          {hideText ? <FaEye size={16} /> : <FaEyeSlash size={17} />}
        </SvgContainer>
      )}
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </InputContainer>
  );
};
