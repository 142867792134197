import styled, { css } from 'styled-components';
import FlagsArea from '../../components/FlagsArea';
import { Modal } from 'reactstrap';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DeliveryHeader = styled.div`
  margin: 10px;
`
export const DeliveryHeaderItemInfo = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #33333310;
`
export const ModalRestaurantInfo = styled.div`
  width: 100%;
  background-color: #FFF;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 72px;
  filter: drop-shadow(5px 5px 10px #33333340);
  transition: all 2000;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  transition: opacity 0.2s ease-in-out;
  z-index: 400000000000000000000;
`
export const StatusRestaurantInfo = styled.span`
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #333;

  ${props => props?.modal && css`
  font-size: 12px;
  `}

  ${props => props?.active && props?.active === true && css`
    color: #2EC9B7;
  `}

  ${props => props?.active && props?.active.toString() === "false" && css`
    color: #FE1933;
  `}

  svg{
    margin-right: 4px;
  }
`
export const LoadingMenu = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(251, 74, 32, 1);
  font-size: 20px;
`;

export const RestaurantLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  overflow: hidden;

  max-width: 50px;
  height: 50px;
  border-radius: 50%;

  img {
    width: 100%;
  }
`;
export const RestaurantName = styled.div`
  margin-left: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #3d3d3d;
`;
export const HasOrdersAdvice = styled.div`
  width: 150px;
  height: 30px;
  background-color: #fff;
  border: 1px solid var(--primary);
  position: absolute;
  bottom: 65px;
  right: 10px;
  border-radius: 8px;
  padding: 10px;

  font-size: 12px;

  &::before {
    content: '';
    border-style: solid;
    border-color: var(--primary) transparent;
    border-width: 6px 6px 0 6px;
    top: 100%;
    bottom: 15px;
    right: 15px;
    position: absolute;
  }
`;

export const CashbackAdvice = styled.div`
  width: 100%;
  min-height: 90px;
  font-family: arial;
  /* background: var(--primary); */

  background: linear-gradient(#43af50, #5dccb1);

  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 28px;
  padding: 10px 15px;
  font-weight: 500;
  z-index: 200000;

  ${props =>
    props.show.toString() === 'false' &&
    css`
      display: none;
    `}
`;

export const RegisterButton = styled.div`
  background-color: #fff;
  /* color: rgba(251, 74, 32, 1); */
  color: #1bd260;
  font-weight: bold;
  border-radius: 19px;
  padding: 7px 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

export const HeaderAction = styled.div`
  position: fixed;
  top: 0;
  /* height: 190px; */
  padding-top: 14px;
  padding-right: 10px;
  background: #fff;
  width: 100%;
  max-width: 410px;
  z-index: 10;

  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > div {
      font-size: 14px;
    }
  }
`;

export const RecentlyProduct = styled.div``;

export const StartingPrice = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #adadad;
`;

export const RecentlyProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--primary);
  color: #fff;
  border-radius: 7px;
  padding: 5px;
  margin-bottom: 16px;
  margin-top: 20px;
  font-weight: bold;
  height: 32px;
  p {
    padding-top: 14px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  display: flex;
  flex-direction: column;
  padding-bottom: 85px;
  margin-top: 65px;

  ${props => props.redirectType.toString() === "delivery" && css`
    margin-top: 0px;
  `}
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 65px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;

  > div {
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      border: none;
      background: transparent;
      svg {
        margin-bottom: 4px;
      }
    }
    span {
      font-size: 12px;
      color: #333;
      margin-top: -20px;
    }
    img {
      width: 26px;
    }
  }
  > div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Poppins';
  text-decoration: none;
  color: #3d3d3d;
  font-weight: 500;

  svg {
    margin-bottom: 4px;
  }
`;

export const CategoryTimes = styled.span`
  display: flex;
  margin-left: -7px;
  justify-content: space-between;
  font-size: 10px;
  font-family: arial;
  padding-right: 10px;
`;

export const MenuContent = styled.div`
  padding: 8px;
  background: #fff;
  border-radius: 7px;
  margin-top: 88px;

  ${props => props.redirectType.toString() === "delivery" &&
    css`
     margin-top: 0px;
    `
  }

  ${props =>
    props.conditionToMarginTop > 23 &&
    props.menuTranslationsEnabled &&
    props.redirectType.toString() !== "delivery" &&
    css`
      margin-top: 115px;
    `}

  ${props =>
    props.conditionToMarginTop > 22 &&
    props.menuTranslationsEnabled &&
    props.redirectType.toString() !== "delivery" &&
    css`
      margin-top: 115px;
    `}

  ${props =>
    props.menuTranslationsEnabled &&
    props.redirectType.toString() !== "delivery" &&
    css`
      margin-top: 115px;
    `}

  ${props =>
    props.conditionToMarginTop > 23 &&
    props.redirectType.toString() !== "delivery" &&
    css`
      margin-top: 115px;
    `}

  @media(max-width: 391px) {
    ${props => props.redirectType.toString() === "delivery" &&
    css`
     margin-top: 0px;
    `
  }

    ${props =>
    props.conditionToMarginTop > 22 &&
    css`
        margin-top: 115px;
      `}
  }

  @media (max-width: 360px) {
    ${props => props.redirectType.toString() === "delivery" &&
    css`
     margin-top: 0px;    `
  }

    ${props =>
    props.conditionToMarginTop > 22 &&
    props.redirectType.toString() !== "delivery" &&
    css`
        margin-top: 115px;
      `}
  }

  @media (max-width: 391px) {
    ${props => props.redirectType.toString() === "delivery" &&
    css`
     margin-top: 0px;    `
  }

    ${props =>
    props.redirectType.toString() !== "delivery" &&
    props.conditionToMarginTop > 22 &&
    props.menuTranslationsEnabled &&
    css`
        margin-top: 146px;
      `}
  }

  @media (max-width: 360px) {
    ${props => props.redirectType.toString() === "delivery" &&
    css`
     margin-top: 0px;    `
  }

    ${props =>
    props.redirectType.toString() !== "delivery" &&
    props.conditionToMarginTop > 22 &&
    props.menuTranslationsEnabled &&
    css`
        margin-top: 146px;
      `}
  }
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;
`;

export const Badge = styled.div`
  font-size: 10px;
  background: var(--primary);
  max-width: 100px;
  padding: 8px;
  min-height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
`;

export const AddToCart = styled.div`
  width: 100%;
  background: transparent;
  text-decoration: none;
  list-style: none;
  border: none;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    img {
      width: 75px;
      height: 65;
      border-radius: 7px;
      margin-right: 5px;
      ${props =>
    props.disable === 'true' &&
    css`
          filter: opacity(40%);
        `}
    }
  }
`;

export const FlagIcon = styled.div`
  top: 0px;
  position: absolute;
  left: 6px;

  img {
    width: 47px;
  }
`;

export const ButtonGoToCart = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff2c3a;
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #d4130d;
  width: 22px;
  height: 22px;
  color: #fff;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewBadge = styled.div`
  position: absolute;
  bottom: 40px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #ffc107;
  width: 45px;
  height: 18px;
  color: black;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RefuseButton = styled.button`
  padding: 8px;
  background: #ff5f5c;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
`;

export const RescueButton = styled.button`
  padding: 8px;
  background: #03db9e;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
`;

export const MenuItemHeader = styled.div`
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-radius: 28px;
  padding: 5px;
  margin-top: 10px;

  span {
    margin-left: 8px;
  }
  strong {
    font-weight: 700;
    margin-left: 8px;
  }
`;

export const MenuItemBody = styled.div`
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  & + div {
    margin-top: 40px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 190px;
  margin-left: 8px;
  color: #333;
  strong {
    font-weight: 900;
  }
  p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${props =>
    props.disable === 'true' &&
    css`
      filter: opacity(30%);
    `}
`;

export const MenuItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  p {
    font-size: 14px;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;
    & + p {
      margin-top: -16px;
    }
  }
`;

export const ButtonAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  color: #3d3d3d;
  font-weight: 500;
`;

export const ModalBody = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 8px;
  display: flex;
  flex-direction: column;

  h4 {
    font-family: Arial, Helvetica, sans-serif;
  }

  h5 {
    font-family: Arial, Helvetica, sans-serif;
    color: green;
    font-size: 34px;
    margin-top: -15px;
  }

  div {
    flex-direction: row;
    margin-bottom: 10px;
    input {
      margin-top: -3px;
      padding: 5px;
      border-radius: 4px;
      border: 1px solid grey;
      float: right;
      height: 30px;
      width: 40%;
      font-size: 15px;
    }
  }
`;

export const MenuItemPromotion = styled.p`
  color: red;
  text-decoration: line-through;
  filter: opacity(60%);
`;

export const SoldOff = styled.div`
  color: red;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: rgba(251, 74, 32, 1);
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const RestaurantClosed = styled.div`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 30px;
  top: 113px;
  background-color: rgba(251, 74, 32, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    color: #fff;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  font-family: 'Arial';
`;

export const ModalHelp = styled(Modal)`
  .modal-content {
    padding: 10px;
  }
`;
