import styled from 'styled-components';

export const FormCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: calc(100dvh - 158px);
  border-radius: 16px 16px 0 0;
  box-shadow: 1px 4px 11.7px 1px rgba(0, 0, 0, 0.12);
`;

export const FormCardMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
`;

export const FormCardFooter = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  justify-content: space-between;
  gap: 8px;

  button {
    border-radius: 10px;
    span {
      font-weight: 700;
    }
  }
`;

export const FormCardHeader = styled.h2`
  color: #333;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
`;

export const FormCardBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  overflow-y: auto;

  span {
    color: #333;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const ShadowElement = styled.div`
  position: sticky;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 20px;
  flex-shrink: 0;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.2s;
`;

export const FormCardInfo = styled.p`
  color: #6c6c6c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;
