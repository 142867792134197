import React from 'react';
import { ModalHeader } from 'reactstrap';
import { CloseButton, StyledModalContainer } from './styles';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../context/auth';

export const ModalCashback = ({ isOpen, toggle, children }) => {
  const { clube } = useAuth();

  return (
    <StyledModalContainer isOpen={isOpen} toggle={toggle}>
      <CloseButton onClick={() => toggle()}>
        <IoMdClose />
      </CloseButton>
      <ModalHeader>
        <h2>Clube {clube.name}</h2>
      </ModalHeader>
      {children}
    </StyledModalContainer>
  );
};
