import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  max-width: 414px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;
  height: 100%;

  display: flex;
  flex-direction: column;
  /* padding-bottom: 65px; */

  a {
    text-decoration: none;
    color: var(--primary);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const InputProductAmount = styled.input`
  color: #ff2c3a;
  border: none;
  width: 62px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  // margin-left: 10px;
  // margin-right: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputComplementAmount = styled.input`
  border: 1px solid #e3e3e3;
  color: #ff2c3a;
  border-radius: 5px;
  width: 62px;
  height: 28px;
  font-size: 16px;
  text-align: center;
`;

export const Footer1 = styled.footer`
  width: 100%; //tem
  max-width: 414px; //tem
  display: flex; //tem
  justify-content: space-between; //tem
  align-items: center;
  padding: 10px; //tem
  box-sizing: border-box; //tem
`;

export const Footer = styled.footer`
  width: 95%;
  max-width: 414px;
  display: flex;
  align-items: center;
  height: 64px;
  position: fixed;
  bottom: 0;
  //left: 0;
  margin: 0 0px;
  background-color: #ffff;
  justify-content: space-between;
  padding: 8px 16px;
  box-sizing: border-box;
  align-items: center;
  gap: 16px;
`;

export const BackButton = styled.button`
  width: 26%;
  flex-grow: 1;
  max-width: 410px;
  font-family: 'Poppins';
  height: 48px;
  background-color: #ffffff;
  color: #fe1a2b;
  border-radius: 100px;
  border: 1px solid #fe1a2b;
  padding: 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    font-weight: bold;
    margin-left: -10px;
  }
`;
export const OrderButton = styled.button`
  width: 60%;
  flex-grow: 1;
  max-width: 410px;
  font-family: 'Poppins';
  height: 48px;
  background-color: #fe1a2b;
  color: #ffffff;
  border-radius: 100px;
  border: none;
  font-weight: bold;

  span {
    font-weight: bold;
  }
`;
export const ProductName = styled.h1`
  margin-top: 30px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
`;

export const ImageProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top: -5px;

  overflow: hidden;

  max-width: 410px;
  height: 250px;

  img {
    width: 100%;
  }
`;

export const DescriptionProduct = styled.div`
  margin-top: 8px;
  padding: 10px;
  padding-bottom: 0;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }

  span {
    font-size: 14px;
  }
`;

export const AddItemsProduct = styled.div`
  padding-right: 10px;
  padding-left: 10px;

  padding-bottom: 20px;
  div {
    li {
      width: 100%;

      display: flex;
      justify-content: space-between;

      padding-bottom: 10px;
      padding-right: 10px;
      padding-left: 10px;

      align-items: center;

      & + li {
        border-top: 1px solid #33333320;
        padding-top: 10px;
      }
    }
  }
`;

export const EditAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 500;
    color: #3d3d3d;
  }
`;

export const EditActionButtons = styled.div`
  width: 123px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  justify-content: center;
  align-items: center !important;
  display: flex;
  color: #ff281e;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  height: 40px;

  p {
    margin-top: 16px;
  }

  button {
    border: 0;
    background: transparent;
  }

  input {
    width: 60px;
    padding: 7px 14px;
    font-family: 'Poppins';
  }
`;

export const InputProductWeight = styled.input`
  width: 150px;
  border-radius: 5px;
  padding: 7px 14px;
  border: 1px solid #33333380;
`;

export const ComplementsHeaderProduct = styled.div`
  margin-top: 15px;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > div {
    width: 100%;
  }
  > p {
    font-size: 10px;
    background: #ff2c3a;
    color: #fff;
    border-radius: 7px;
    padding: 4px;
    display: flex !important;
    flex-direction: column;
    justify-content: end;
    // margin-top: 23px;
    // margin-bottom: 0px;
  }
`;

export const ComplementItem = styled.div`
  width: 100%;
  max-width: 414px;
  display: flex;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ComplementItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 98%;
  padding-left: 10px;
`;

export const ComplementItemDescriptionLimit = styled.span`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--primary);
`;

export const ComplementItemAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    margin-right: 10px;
    margin-left: 10px;

    color: #ff281e;
  }
`;

export const InformationText = styled.div`
  margin-top: 20px;
  margin-bottom: 48px;

  p {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    font-size: 15px;
    svg {
      margin-right: 6px;
    }
  }

  input {
    width: 100%;
    max-width: 414px;
    height: 48px;

    margin-top: 8px;
    padding: 10px 16px;

    color: ##cdcdcd;
    border-radius: 8px;
    border: 1px solid #cdcdcd;

    ::placeholder {
      color: #4d4d4c;
      font-size: 14px;
    }
  }
`;

export const ModalTitle = styled.div`
  padding: 10px;
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
  font-family: 'Poppins';
`;

export const QuestionSubtitle = styled.span`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  > div {
    width: 100%;
  }
  > p {
    font-size: 10px;
    background: #ff2c3a;
    color: #fff;
    border-radius: 7px;
    padding: 4px;
    margin-bottom: 0px;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  margin-top: 10px;
  width: 100%;
  svg {
    color: #6c6c6c;
  }
  input {
    width: 100%;
    font-size: 15px;
    background: #fff;
    color: #4d4d4c;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding: 8px 40px 8px 38px;
    height: 40px;
    ::placeholder {
      color: #6c6c6c;
    }
  }
`;

export const ButtonSearchInput = styled.div`
  position: absolute;
  top: 40%;
  left: 8px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 8px;
  pointer-events: none;
`;

export const ButtonDeleteInput = styled.button`
  position: absolute;
  top: 40%;
  right: 8px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 8px;
  pointer-events: all;
`;
