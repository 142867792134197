import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import OrdersFinished from './OrdersFinished';
import OrdersInProgress from './OrdersInProgress';

import { Container, Content, Header, Main, ButtonOrders, Nav } from './styles';
import { useAuth } from '../../../context/auth';
import { useBaskets } from '../../../context/BasketsContext';

const Orders = () => {
  const history = useHistory();
  const { restaurantName, websocketManager, isScheduling } = useAuth();
  const { baskets, fetchData, setBaskets } = useBaskets();

  const [defaulIndexState, setDefaultIndexState] = useState(0);
  function handleNavigate() {
    history.push(`/${restaurantName}`);
  }

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(2, data => {
        console.log('ClientOrders: socket new data', data);
        const { basket_id, basket_status } = data.item;
        if (data.type === 'status-changed') {
          if (basket_status === 'finished') {
            setDefaultIndexState(2);
          }

          setBaskets(state => {
            const newOrders = [...state];

            const idx = newOrders.findIndex(s => s.id === basket_id);

            if (idx !== -1) {
              newOrders[idx].order_status = basket_status;
            }

            return newOrders;
          });
          // fetchData();
        }
      });
    }
  }, [websocketManager, setBaskets, setDefaultIndexState]);

  return (
    <Container>
      <Content>
        <Header>
          <h1>Meus Pedidos</h1>
        </Header>

        <Main>
          {isScheduling.toString() === 'true' && (
            <Tabs defaultIndex={1}>
              <Nav>
                <TabList>
                  <Tab>Em andamento</Tab>
                  <Tab>Agendados</Tab>
                  <Tab>Finalizadas</Tab>
                </TabList>
              </Nav>

              <TabPanel>
                <OrdersInProgress ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersInProgress schedulingList ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersFinished ordersFinished={baskets} />
              </TabPanel>
            </Tabs>
          )}

          {defaulIndexState === 0 && (
            <Tabs defaultIndex={0}>
              <Nav>
                <TabList>
                  <Tab>Em andamento</Tab>
                  <Tab>Agendados</Tab>
                  <Tab>Finalizadas</Tab>
                </TabList>
              </Nav>

              <TabPanel>
                <OrdersInProgress ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersInProgress schedulingList ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersFinished ordersFinished={baskets} />
              </TabPanel>
            </Tabs>
          )}

          {defaulIndexState === 2 && (
            <Tabs defaultIndex={2}>
              <Nav>
                <TabList>
                  <Tab>Em andamento</Tab>
                  <Tab>Agendados</Tab>
                  <Tab>Finalizadas</Tab>
                </TabList>
              </Nav>

              <TabPanel>
                <OrdersInProgress ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersInProgress schedulingList ordersInProgress={baskets} />
              </TabPanel>

              <TabPanel>
                <OrdersFinished ordersFinished={baskets} />
              </TabPanel>
            </Tabs>
          )}
        </Main>

        <ButtonOrders onClick={handleNavigate}>
          <FiArrowLeft />
          Voltar para Cardápio
        </ButtonOrders>
      </Content>
    </Container>
  );
};

export default Orders;
