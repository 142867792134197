import React, { createContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { getAddressByCep } from '../../utils/functions';
import { useAuth } from '../auth';
import { toast } from 'react-toastify';
import { apiClub } from '../../services/apiClub';
import { isValid } from 'date-fns';

export const ClubFormContext = createContext();

export const ClubFormProvider = ({ children }) => {
  const { userPhone, keyClube } = useAuth();
  const [formStep, setFormStep] = useState(0);

  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const toggleModalConfirmation = () =>
    setModalConfirmationOpen(state => !state);

  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const toggleModalError = () => setModalErrorOpen(state => !state);

  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    birthday: '',
    email: '',
    password: '',
    confirmPassword: '',
    cep: '',
    city: '',
    state: '',
    bairro: '',
    address: '',
    number: '',
    phone: userPhone || '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    setFormData(state => {
      return {
        ...state,
        phone: userPhone,
      };
    });
  }, [userPhone]);

  const schemas = [
    yup.object().shape({
      phone: yup
        .string()
        .required('Telefone é obrigatório')
        .min(15, 'Telefone inválido'),
    }),
    yup.object().shape({
      name: yup.string().required('Nome é obrigatório'),
      gender: yup.string().required('Gênero é obrigatório'),
      birthday: yup.string().test('birthday', 'Data inválida', value => {
        if (value.length < 10) return false;
        const splitDate = value.split('/');
        const [day, month, year] = splitDate;
        const date = new Date(`${year}-${month}-${day}`);
        console.log('date: ', date);
        return isValid(date);
      }),
    }),
    yup.object().shape({
      email: yup
        .string()
        .email('Email inválido')
        .required('Email é obrigatório'),
      password: yup
        .string()
        .required('Senha é obrigatória')
        .min(6, 'Senha muito curta. Mínimo de 6'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Senhas devem ser iguais'),
    }),
    yup.object().shape({
      cep: yup.string().required('CEP é obrigatório').min(10, 'CEP inválido'),
      city: yup.string().required('Cidade é obrigatória'),
      state: yup.string().required('Estado é obrigatório'),
      bairro: yup.string().required('Bairro é obrigatório'),
      address: yup.string().required('Rua é obrigatória'),
      number: yup.string().required('Número é obrigatório'),
    }),
  ];

  const updateForm = (field, value) => {
    setFormData(state => {
      return {
        ...state,
        [field]: value,
      };
    });
  };

  const getFormValue = field => {
    return formData[field];
  };

  const validateForm = async () => {
    try {
      setFormErrors({});
      await schemas[formStep].validate(formData, { abortEarly: false });
      return true;
    } catch (error) {
      console.log('error validation: ', JSON.stringify(error, null, 2));
      error.inner.forEach(item => {
        setFormErrors(state => {
          return {
            ...state,
            [item.path]: item.errors[0],
          };
        });
      });
      return false;
    }
  };

  const handleCep = async cep => {
    setLoadingAddress(true);
    const addressInfo = await getAddressByCep(cep);

    updateForm('city', addressInfo.localidade);
    updateForm('state', addressInfo.uf);
    updateForm('bairro', addressInfo.bairro);
    updateForm('address', addressInfo.logradouro);
    setLoadingAddress(false);
  };

  const submitClubForm = async () => {
    setLoadingSubmit(true);
    try {
      const validation = await validateForm(3);

      if (!validation) {
        setLoadingSubmit(false);
        return;
      }

      const splitDate = formData.birthday.split('/');
      const [day, month, year] = splitDate;
      const date = new Date(`${year}-${month}-${day}`);

      await apiClub.post(`/public/client/${keyClube}`, {
        ...formData,
        birthday: date,
        phone: formData.phone.replace(/(\s|-)/g, ''),
      });

      toggleModalConfirmation();
    } catch (err) {
      console.log('submitClubForm error: ', err);
      const errMessage = err.response?.data?.error;
      if (errMessage) {
        toast.error(errMessage);
      } else {
        toast.error('Erro ao cadastrar no clube. Tente novamente mais tarde');
      }
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (formData.cep.length === 10) {
      handleCep(formData.cep);
    }
  }, [formData.cep]);

  return (
    <ClubFormContext.Provider
      value={{
        getFormValue,
        updateForm,
        validateForm,
        formErrors,
        loadingAddress,
        submitClubForm,
        modalConfirmationOpen,
        toggleModalConfirmation,
        formStep,
        setFormStep,
        loadingSubmit,
        modalErrorOpen,
        toggleModalError,
      }}
    >
      {children}
    </ClubFormContext.Provider>
  );
};
