import React, { useContext } from 'react';
import FormCard from '../../FormCard';
import registerConfirmation from '../../../../assets/images/register-confirmation.svg';
import { ConfirmationContainer } from './styles';
import { ClubFormContext } from '../../../../context/clubForm';
import { Button } from 'ui-kit-takeat';

export const Confirmation = () => {
  const { getFormValue, setFormStep, toggleModalError } = useContext(
    ClubFormContext,
  );

  return (
    <>
      <FormCard.Header>Confirmação</FormCard.Header>
      <FormCard.Body>
        <ConfirmationContainer>
          <img src={registerConfirmation} />
          <p>
            Por favor, verifique se o número de telefone inserido ao fazer o
            pedido está correto
          </p>
          <span>{getFormValue('phone')}</span>
        </ConfirmationContainer>
      </FormCard.Body>
      <FormCard.Buttons vertical>
        <Button
          title="É o meu número"
          buttonColor="#2ec9b7"
          onClick={() => setFormStep(state => state + 1)}
        />
        <Button
          title="NÃO é o meu número"
          inverted
          onClick={() => toggleModalError()}
        />
      </FormCard.Buttons>
    </>
  );
};
