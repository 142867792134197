/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useCallback } from 'react';
import { FiSmartphone } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { Spinner } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import { useAuth } from '../../context/auth';
import pixIcon from '../../assets/images/pix_icon.png';
import api from '../../services/api';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  Footer,
  ItemListHeader,
  Orders,
  ConfirmButton,
  CancelButton,
  ModalFooter,
  ModalTitle,
  ConfirmPixButton,
  PriceText,
  ConfirmCreditButton,
  CloseButton,
  AreaFinalPrice,
  AreaButtons,
  DescriptionText,
  ConfirmButtonOutline,
  CloseButtonPix,
} from './styles';
import formatCompletDate from '../../utils/formatCompleteDate';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import { Button } from 'ui-kit-takeat';
import { toast } from 'react-toastify';

const IndividualAccount = () => {
  const {
    userPhone,
    sessionKey,
    billId,
    restaurantTaxService,
    restaurantTaxServiceValue,
    logOut,
    restaurantAcceptsPix,
    formatValue,
    tableType,
    restaurantId,
    restaurantAcceptsPaytime,
    setLastPixCode,
    lastPixCode,
    requireTableNumber
  } = useAuth();
  const [dataIndividual, setDataIndividual] = useState([]);
  const history = useHistory();
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const [modalButton, setModalButton] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [cashback, setCashback] = useState(0);
  const [buttonCopyText, setButtonCopyText] = useState('Copiar');
  const [isModalInformCommandTableNumberOpened, setIsModalInformCommandTableNumberOpened] = useState(false)
  function toggleModalCommandTableNumber() {
    setIsModalInformCommandTableNumberOpened(!isModalInformCommandTableNumberOpened)
  }

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
      border: 'none',
      boxShadow: '0px 0px 8.4px 0px rgba(0, 0, 0, 0.16)',
    },
  };

  const [pixCode, setPixCode] = useState(null); // '00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/b94014e4-a1ed-4874-b648-cbdda61fbfa05204000053039865802BR5925Zoop Tecnologia E Meios D6009SAO PAULO62070503***63046FB1');
  const [loadingPix, setLoadingPix] = useState(false);
  const [totalPix, setTotalPix] = useState(null); // '56,47');
  const [pixId, setPixId] = useState(null);
  const [buttonVerifyPix, setButtonVerifyPix] = useState(
    'Já efetuei o pagamento!',
  );
  const [buttonCopyTextFooter, setButtonCopyTextFooter] = useState(
    'Copiar Código Pix',
  );

  async function verifyPix() {
    try {
      setButtonVerifyPix('Aguarde...');
      console.log(pixId);
      const response = await api.post('client/verify-pix', {
        zoop_id: pixId,
      });

      if (response.data.paid) {
        history.push('/app/review');
        setLastPixCode('');
      } else {
        setButtonVerifyPix(
          'Pagamento não encontrado. Se já pagou, tente novamente!',
        );
      }
    } catch (err) {
      alert('erro no pix', err);
    }
    setLoadingPix(false);
  }

  async function closeModalPixIndividual() {
    try {
      setLoadingPix(true);
      const response = await api.post('client/create-pix-individual', {
        session_key: sessionKey,
        phone: userPhone,
        individual_bill_id: billId,
      });

      setPixCode(response.data.codigo);
      setLastPixCode(response.data.codigo);
      setTotalPix(
        response.data.total_pix.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
      );
      setPixId(response.data.id);
    } catch (err) {
      alert('erro no pix');
    }
    setLoadingPix(false);
  }

  function closeModal() {
    if (tableType === "command") {
      setIsOpen(false);
      toggleModalCommandTableNumber()
    } else {
      setIsOpen(false);
      modalAction[0]();
    }
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  const systemErrors = useCallback(() => {
    logOut();
    history.push('/');
  }, [history, logOut]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get('client/bills', {
          params: { session_key: sessionKey },
        });

        const dataIndividualTable = res.data;

        if (
          dataIndividualTable.rescues &&
          dataIndividualTable.rescues.length > 0
        ) {
          const cashback = parseFloat(dataIndividualTable.rescues[0].cashback);
          setCashback(cashback);
        }

        setDataIndividual([dataIndividualTable]);
      } catch (err) {
        if (!err.response.ok) {
          if (err.response.data.errorType === 'no_buyer_bill_found') {
            openModal('Usuário sem conta nesta mesa');
            setModalAction([
              () => {
                systemErrors();
              },
            ]);
          }

          if (err.response.data.errorType === 'closed_table_session') {
            openModal(
              'A comanda da mesa já foi fechada, favor ler o qrCode novamente para abrir outra comanda',
            );
            setModalAction([
              () => {
                systemErrors();
              },
            ]);
          }

          if (err.response.data.errorType === 'table_session_not_found') {
            openModal(
              'Sessão da mesa não encontrada, por favor tente novamente',
            );
            setModalAction([
              () => {
                systemErrors();
              },
            ]);
          }
        }
      }
    }

    tableType !== 'prepaid' && fetchData();
  }, [sessionKey, history, logOut, systemErrors, tableType]);

  const getUserPrepaidOrders = useCallback(async () => {
    const response = await api.get(`/client/prepaid/sessions/${restaurantId}`);

    setDataIndividual(response.data);
  }, [restaurantId]);

  useEffect(() => {
    tableType === 'prepaid' && getUserPrepaidOrders();
  }, [tableType, getUserPrepaidOrders]);

  async function handleCloseAccount() {
    try {
      await api.put('client/close-individual-bill', {
        session_key: sessionKey,
        bill_id: billId,
      });
      setLastPixCode('');
      history.push('/app/review');
    } catch (err) {
      if (!err.response.ok) {
        if (err.response.data.errorType === 'table_session_not_found') {
          openModal('Sessão da mesa não encontrada, por favor tente novamente');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }

        if (err.response.data.errorType === 'individual_bill_already_closed') {
          openModal('Conta do usuário já fechada');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }
      }
    }
  }


  async function handleCloseAccountWithData(data) {
    if (requireTableNumber && !data.command_table_number) {
      toast.error("Favor informar a mesa.")

      return false
    }
    try {
      await api.put('client/close-table-bill', {
        session_key: sessionKey,
        bill_id: billId,
        command_table_number: data.command_table_number || null
      });

      setLastPixCode('');
      setIsModalInformCommandTableNumberOpened(false)

      history.push('/app/review');
    } catch (err) {
      if (!err.response.ok) {
        if (err.response.data.errorType === 'table_session_not_found') {
          openModal('Sessão da mesa não encontrada, por favor tente novamente');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }
      }
    }
  }

  let totalIndividualPrice = dataIndividual.reduce(
    (acum, curr) => acum + curr.total_service_price,
    0,
  );

  totalIndividualPrice -= cashback;

  function replaceValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  function handleGoCloseCredit() {
    history.push({
      pathname: '/app/close-table-credit',
      account: 'individual',
    });
  }

  const [isModalCopiedPixOpened, setIsModaCopiedPixOnened] = useState(false);

  function toggleModalCopiedPix() {
    setIsModaCopiedPixOnened(!isModalCopiedPixOpened);
  }

  return (
    <Container>
      <Content>
        <Modal
          isOpen={isModalInformCommandTableNumberOpened}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Informe o número da comanda"
          onRequestClose={toggleModalCommandTableNumber}
        >
          <Form onSubmit={handleCloseAccountWithData}>
            <ModalTitle>
              <p>Informe o número da mesa:</p>
            </ModalTitle>
            <Input name="command_table_number" type="text" />
            <ModalFooter>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type='button' title='Cancelar' inverted onClick={toggleModalCommandTableNumber} />
                <Button type='submit' title='Enviar' />
              </div>
            </ModalFooter>
          </Form>
        </Modal>
        <Modal
          isOpen={isModalCopiedPixOpened}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
          onRequestClose={toggleModalCopiedPix}
        >
          <>
            <ModalTitle>
              <p style={{ fontWeight: 'bold' }}>Código copiado com sucesso!</p>
              <p style={{ fontSize: 14 }}>
                Com o cídogo pix copiado, abra a área PIX no app do seu banco,
                cole o código e efetue o pagamento.
              </p>
            </ModalTitle>
            <ModalFooter>
              <ConfirmButtonOutline onClick={() => verifyPix()}>
                {buttonVerifyPix}
              </ConfirmButtonOutline>

              <ConfirmPixButton
                onClick={() => {
                  closeModalPixIndividual();
                  setIsOpen(true);
                  setModalButton(true);
                  toggleModalCopiedPix();
                  setButtonVerifyPix('Já efetuei pagamento');
                  setButtonCopyText('Copiar');
                }}
              >
                Gerar novo código Pix online
                <img
                  src={pixIcon}
                  alt="logo do pix"
                  style={{ marginLeft: 8 }}
                />
              </ConfirmPixButton>

              {/* {modalButton.toString() === 'true' && (
                  <CancelButton
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                )} */}
            </ModalFooter>
          </>
        </Modal>
        <Modal
          isOpen={modalIsOpen}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          {pixCode ? (
            <>
              <ModalTitle>
                <p style={{ fontWeight: 'bold' }}>Gerar novo código Pix</p>
                <p style={{ fontSize: 14 }}>
                  Copie o código PIX abaixo, abra a área PIX no app do seu
                  banco, cole o código e efetue o pagamento.
                </p>
              </ModalTitle>
              <ModalFooter>
                <p style={{ textAlign: 'left' }}>Código Pix Copia e Cola</p>
                <div style={{ display: 'flex' }}>
                  <input value={pixCode} />
                  <ConfirmPixButton
                    style={{ marginLeft: 8, marginRight: 0 }}
                    onClick={() => {
                      navigator.clipboard.writeText(pixCode);
                      setButtonCopyText('Copiado!');
                    }}
                  >
                    {buttonCopyText}
                  </ConfirmPixButton>
                </div>

                <PriceText style={{ fontSize: '27px' }}>R${totalPix}</PriceText>
                <p style={{ textAlign: 'left' }}>
                  <strong>
                    O Código expira em 5 minutos! <br />
                    Comunique o pagamento ao garçom
                  </strong>
                </p>

                <ConfirmButtonOutline onClick={() => verifyPix()}>
                  {buttonVerifyPix}
                </ConfirmButtonOutline>
                <CloseButton
                  onClick={() => setPixCode(null)}
                  style={{ marginBottom: 10 }}
                >
                  Escolher outros métodos
                </CloseButton>
                {modalButton.toString() === 'true' && (
                  <CancelButton
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                )}
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalTitle>
                {!loadingPix && (
                  <p style={{ textAlign: 'left' }}>{modalPhrase}</p>
                )}
              </ModalTitle>
              <ModalFooter
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {modalButton.toString() === 'true' && (
                  <>
                    {restaurantAcceptsPix.toString() === 'true' && (
                      <>
                        {loadingPix ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <PulseLoader color="#FF2129" />
                            <p>Gerando código Pix...</p>
                          </div>
                        ) : (
                          <>
                            <ConfirmPixButton onClick={closeModalPixIndividual}>
                              Pix online
                              <img
                                src={pixIcon}
                                alt="logo do pix"
                                style={{ marginLeft: 8 }}
                              />
                            </ConfirmPixButton>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {restaurantAcceptsPaytime.toString() === 'true' && !loadingPix && (
                  <>
                    <ConfirmCreditButton onClick={handleGoCloseCredit}>
                      Cartão de crédito online
                    </ConfirmCreditButton>
                  </>
                )}

                {!loadingPix && (
                  <ConfirmButton onClick={closeModal} style={{ marginTop: 10 }}>
                    Outros métodos - chamar o garçom
                  </ConfirmButton>
                )}

                {modalButton.toString() === 'true' && !loadingPix && (
                  <CancelButton
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                )}
              </ModalFooter>
            </>
          )}
        </Modal>
        <ItemListHeader>
          <div className="menu-item-header">
            <strong>Seus pedidos</strong>
            <div className="userPhone">
              <p>
                <FiSmartphone style={{ marginRight: 5 }} color="#333" />
                {userPhone}
              </p>
            </div>
          </div>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {tableType === 'prepaid'
              ? dataIndividual &&
              dataIndividual.length > 0 &&
              dataIndividual.map(ordersmap => (
                <div className="orders" key={ordersmap.id}>
                  {ordersmap.bills.map(item =>
                    item.order_baskets.map(ordermap => (
                      <Orders key={ordermap.id}>
                        <p>Pedido #{ordermap.basket_id}</p>
                        {ordermap.orders.map(order => {
                          return (
                            <div className="menu-item-body" key={order.id}>
                              <span>
                                {formatCompletDate(ordermap.start_time)}
                              </span>
                              <div className="menu-item-img">
                                {/* {order.product.image && (
                                    <img
                                      src={order.product.image.url_thumb || order.product.image.url}
                                      alt={order.product.name}
                                      style={{ width: 64 }}
                                    />
                                  )} */}

                                <div className="menu-item-description">
                                  {order.use_weight ? (
                                    <strong>
                                      {`${replaceValue(order.weight)} kg ${order.product.name
                                        }`}
                                    </strong>
                                  ) : (
                                    <strong>{`${order.amount}x ${order.product.name}`}</strong>
                                  )}
                                </div>
                              </div>

                              <div>
                                <span>{formatValue(order.total_price)}</span>
                              </div>
                            </div>
                          );
                        })}
                        {/* {ordersmap.rescues.map(rescue => (
                            <div
                              className="menu-item-body"
                              key={rescue.id}
                              style={{ color: '#018D66' }}
                            >
                              <div className="menu-item-img">
                                <div className="menu-item-description">
                                  <strong>Resgate Cashback</strong>
                                </div>
                              </div>

                              <div>
                                <p>{formatValue(rescue.cashback)}</p>
                              </div>
                            </div>
                          ))} */}
                      </Orders>
                    )),
                  )}
                </div>
              ))
              : dataIndividual.map(ordersmap => (
                <div className="orders" key={ordersmap.id}>
                  {ordersmap.order_baskets.map(ordermap => (
                    <Orders key={ordermap.id}>
                      <p>Pedido #{ordermap.basket_id}</p>
                      {ordermap.orders.map(order => {
                        return (
                          <div className="menu-item-body" key={order.id}>
                            <div className="menu-item-img">
                              {order.product.image && (
                                <img
                                  src={
                                    order.product.image.url_thumb ||
                                    order.product.image.url
                                  }
                                  alt={order.product.name}
                                  style={{ width: 64 }}
                                />
                              )}

                              <div className="menu-item-description">
                                {order.use_weight ? (
                                  <strong>
                                    {`${replaceValue(order.weight)} kg ${order.product.name
                                      }`}
                                  </strong>
                                ) : (
                                  <strong>{`${order.amount}x ${order.product.name}`}</strong>
                                )}
                              </div>
                            </div>

                            <div>
                              <p>{formatValue(order.total_price)}</p>
                            </div>
                          </div>
                        );
                      })}
                    </Orders>
                  ))}
                  {ordersmap.rescues.map(rescue => (
                    <div
                      className="menu-item-body"
                      key={rescue.id}
                      style={{ color: '#018D66' }}
                    >
                      <div className="menu-item-img">
                        <div className="menu-item-description">
                          <strong>Resgate Cashback</strong>
                        </div>
                      </div>

                      <div>
                        <p>{formatValue(rescue.cashback)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </MenuItem>
        </MenuContent>

        <Footer>
          {/* <input placeholder="Adicione o cupom" /> */}
          <AreaFinalPrice>
            {tableType !== 'prepaid' && (
              <>
                <DescriptionText>
                  Você está solicitando o fechamento da sua conta. <br />O total
                  parcial no momento é de:
                </DescriptionText>

                {restaurantTaxService.toString() === 'true' && (
                  <p style={{ fontSize: 12 }}>
                    {' '}
                    Com os {restaurantTaxServiceValue}% de serviço já incluso
                    (Opcional)
                  </p>
                )}

                <PriceText>{formatValue(totalIndividualPrice)}</PriceText>
              </>
            )}
          </AreaFinalPrice>

          {tableType !== 'prepaid' && (
            <AreaButtons>
              <CloseButton
                type="button"
                onClick={() => {
                  openModal('Qual forma de pagamento?');
                  setModalButton(true);
                  setModalAction([
                    () => {
                      handleCloseAccount();
                    },
                  ]);
                }}
              >
                Fechar minha conta
              </CloseButton>

              {restaurantAcceptsPix &&
                (lastPixCode !== null && lastPixCode !== '' ? (
                  <CloseButtonPix
                    onClick={() => {
                      navigator.clipboard.writeText(lastPixCode);
                      setButtonCopyTextFooter('Copiado!');
                      toggleModalCopiedPix();
                      setButtonVerifyPix('Já efetuei o pagamento');
                    }}
                  >
                    Copiar Pix online novamente{' '}
                    <img
                      src={pixIcon}
                      alt="logo do pix"
                      style={{ marginLeft: 8 }}
                    />
                  </CloseButtonPix>
                ) : (
                  <CloseButtonPix
                    type="button"
                    onClick={() => {
                      closeModalPixIndividual();
                      setIsOpen(true);
                      setModalButton(true);
                    }}
                  >
                    {loadingPix
                      ? 'Gerando código Pix...'
                      : 'Fechar conta com Pix online'}
                  </CloseButtonPix>
                ))}
            </AreaButtons>
          )}

          {tableType === 'prepaid' && (
            <AreaButtons>
              <CloseButton
                type="button"
                onClick={() => {
                  history.push(`/menu/${restaurantId}`);
                }}
              >
                Voltar ao Cardápio
              </CloseButton>
            </AreaButtons>
          )}
        </Footer>
      </Content>
    </Container>
  );
};

export default IndividualAccount;
