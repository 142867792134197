import axios from 'axios';

export function startingPrice(complement_categories, isDelivery) {
  const requiredCategories = complement_categories?.filter(cat => !cat.optional);

  const value = requiredCategories?.reduce((acc, cat) => {
    if (isDelivery ? cat.cheapest_delivery || cat.cheapest : cat.cheapest) {
      return (
        acc +
        parseFloat(
          isDelivery ? cat.cheapest_delivery || cat.cheapest : cat.cheapest,
        )
      );
    }
    const cheapestComplement = Math.min(
      ...cat.complements.map(comp =>
        isDelivery ? comp.delivery_price || comp.price : comp.price,
      ),
    );

    return acc + cheapestComplement * cat.minimum;
  }, 0);

  return value;
}

export const getAddressByCep = async cep => {
  const formattedCep = cep.replace(/(\.|-)/gi, '');
  const response = await axios.get(
    `https://viacep.com.br/ws/${formattedCep}/json/`,
  );

  return response.data;
};

export const maskString = (string, percent = 50, char = '*') => {
  const length = string.length;
  const charAmount = length * (percent / 100);
  const firstAmount = Math.floor((length - charAmount) / 2);

  return (
    string.substring(0, firstAmount) +
    char.repeat(charAmount) +
    string.substring(firstAmount + charAmount)
  );
};
