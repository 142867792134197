import React, { useContext, useEffect } from 'react';
import FormCard from '../../FormCard';
import { CustomInput } from '../../CustomInput';
import { ClubFormContext } from '../../../../context/clubForm';
import { CustomSelect } from '../../CustomSelect';
import { STATES } from '../../../../utils/constants';
import { Button, Spinner } from 'ui-kit-takeat';

export const Address = () => {
  const {
    getFormValue,
    updateForm,
    formErrors,
    loadingAddress,
    submitClubForm,
    setFormStep,
    loadingSubmit,
  } = useContext(ClubFormContext);

  useEffect(() => {
    const firstItem = Object.keys(formErrors)[0];
    if (firstItem) {
      const element = document.getElementsByName(firstItem)[0];
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [formErrors]);

  return (
    <>
      <FormCard.Header>Endereço</FormCard.Header>
      <FormCard.Body>
        <CustomInput
          name="cep"
          title="CEP*"
          placeholder="xx.xxx-xxx"
          mask="99.999-999"
          value={getFormValue('cep')}
          onChange={e => updateForm('cep', e.target.value)}
          error={formErrors.cep}
        />
        {getFormValue('cep').length === 10 &&
          (loadingAddress ? (
            <Spinner inverted style={{ margin: '0 auto' }} />
          ) : (
            <>
              <CustomInput
                name="city"
                title="Cidade*"
                placeholder="Digite o CEP"
                value={getFormValue('city')}
                onChange={e => updateForm('city', e.target.value)}
                error={formErrors.city}
              />
              <CustomSelect
                label="Estado*"
                name="state"
                placeholder="Selecione"
                controlStyles={`border-radius: 10px; height: 48px;`}
                options={STATES}
                value={
                  STATES.find(
                    option => option.value === getFormValue('state'),
                  ) || null
                }
                onChange={e => updateForm('state', e.value)}
                error={formErrors.state}
              />
              <CustomInput
                name="bairro"
                title="Bairro*"
                placeholder="Digite o CEP"
                value={getFormValue('bairro')}
                onChange={e => updateForm('bairro', e.target.value)}
                error={formErrors.bairro}
              />
              <CustomInput
                name="address"
                title="Rua*"
                placeholder="Digite o CEP"
                value={getFormValue('address')}
                onChange={e => updateForm('address', e.target.value)}
                error={formErrors.address}
              />
              <CustomInput
                name="number"
                title="Número*"
                placeholder="Digite o número"
                value={getFormValue('number')}
                onChange={e => updateForm('number', e.target.value)}
                error={formErrors.number}
              />
            </>
          ))}
      </FormCard.Body>
      <FormCard.Buttons>
        <Button
          title="Voltar"
          onClick={() => setFormStep(state => state - 1)}
          inverted
        />
        <Button
          title="Finalizar"
          onClick={() => submitClubForm()}
          buttonColor="#2ec9b7"
          containerStyles={{ flexGrow: 1 }}
          disabled={loadingSubmit}
          isLoading={loadingSubmit}
        />
      </FormCard.Buttons>
    </>
  );
};
