import styled from 'styled-components';

export const BackButtonContainer = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;

  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ff2c3a;
`;
