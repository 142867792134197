/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiCopy, FiSmartphone, FiUserMinus } from 'react-icons/fi';
import { MdPix } from 'react-icons/md';
import Modal from 'react-modal';
import { useAuth } from '../../context/auth';
import api from '../../services/api';

import formatTime from '../../utils/formatTime';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  MenuItemHeader,
  MenuItemBody,
  Footer,
  Orders,
  ItemListHeader,
  ServiceTax,
  DescriptionText,
  PriceText,
  ConfirmButton,
  ModalFooter,
  ModalTitle,
  CancelButton,
  ConfirmPixButton,
  OtherPayments,
  ButtonLink,
  CloseButton,
  ConfirmCreditButton,
  CloseButtonPix,
  AreaButtons,
  AreaFinalPrice,
  ConfirmButtonOutline,
} from './styles';

import { PulseLoader } from 'react-spinners';
import pixIcon from '../../assets/images/pix_icon.png';
import { Form } from '@unform/web';
import { Button } from 'ui-kit-takeat';
import { toast } from 'react-toastify';
import Input from '../../components/Input'

const TableAccount = () => {
  const {
    logOut,
    sessionKey,
    restaurantTaxService,
    restaurantTaxServiceValue,
    setTableNumber,
    billId,
    userPhone,
    restaurantAcceptsPix,
    formatValue,
    restaurantUseCommand,
    lastPixCode,
    setLastPixCode,
    tableType,
    restaurantAcceptsPaytime,
    requireTableNumber
  } = useAuth();

  const [totalTable, setTotalTable] = useState([]);
  const [tablePayments, setTotalPayments] = useState('');
  const history = useHistory();
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const [modalButton, setModalButton] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [buttonCopyText, setButtonCopyText] = useState('Copiar');
  const [buttonCopyTextFooter, setButtonCopyTextFooter] = useState(
    'Copiar Código Pix',
  );
  const [isModalInformCommandTableNumberOpened, setIsModalInformCommandTableNumberOpened] = useState(false)
  function toggleModalCommandTableNumber() {
    setIsModalInformCommandTableNumberOpened(!isModalInformCommandTableNumberOpened)
  }

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
      border: 'none',
      boxShadow: '0px 0px 8.4px 0px rgba(0, 0, 0, 0.16)',
    },
  };

  function closeModal() {
    if (tableType === "command") {
      setIsOpen(false);
      toggleModalCommandTableNumber()
    } else {
      setIsOpen(false);
      modalAction[0]();
    }
  }

  const [pixCode, setPixCode] = useState(null); //'00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/b94014e4-a1ed-4874-b648-cbdda61fbfa05204000053039865802BR5925Zoop Tecnologia E Meios D6009SAO PAULO62070503***63046FB1');
  const [loadingPix, setLoadingPix] = useState(false);
  const [totalPix, setTotalPix] = useState(null); //'56,47');
  const [pixId, setPixId] = useState(null);
  const [buttonVerifyPix, setButtonVerifyPix] = useState(
    'Já efetuei o pagamento!',
  );

  const [totalLounge, setTotalLounge] = useState(null);

  async function closeModalPix() {
    try {
      setLoadingPix(true);
      const response = await api.post('client/create-pix', {
        session_key: sessionKey,
        phone: userPhone,
      });

      setPixCode(response.data.codigo);
      setLastPixCode(response.data.codigo);
      setTotalPix(
        response.data.total.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
      );
      setPixId(response.data.id);
    } catch (err) {
      alert('erro no pix', err);
    }
    setLoadingPix(false);
  }

  async function verifyPix() {
    try {
      setButtonVerifyPix('Aguarde...');

      const response = await api.post('client/verify-pix', {
        zoop_id: pixId,
      });

      if (response.data.paid) {
        history.push('/app/review');
        setLastPixCode('');
      } else {
        setButtonVerifyPix(
          'Pagamento não encontrado. Se já pagou, tente novamente!',
        );
      }
    } catch (err) {
      alert('erro no pix', err);
      setButtonVerifyPix(
        'Pagamento não encontrado. Se já pagou, tente novamente!',
      );
    }
    setLoadingPix(false);
  }

  async function closeModalPixIndividual() {
    try {
      setLoadingPix(true);
      const response = await api.post('client/create-pix-individual', {
        session_key: sessionKey,
        phone: userPhone,
        individual_bill_id: billId,
      });

      setPixCode(response.data.codigo);
      setTotalPix(
        response.data.total_pix.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        }),
      );
      setPixId(response.data.id);
    } catch (err) {
      alert('erro no pix');
    }
    setLoadingPix(false);
  }

  function closeIndividualTable() {
    setIsOpen(false);

    handleCloseIndividualAccount();
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  const systemErrors = useCallback(() => {
    logOut();
    history.push('/');
  }, [history, logOut]);

  async function handleCloseIndividualAccount() {
    try {
      await api.put('client/close-individual-bill', {
        session_key: sessionKey,
        bill_id: billId,
      });

      history.push('/app/review');
    } catch (err) {
      if (!err.response.ok) {
        if (err.response.data.errorType === 'table_session_not_found') {
          openModal('Sessão da mesa não encontrada, por favor tente novamente');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }

        if (err.response.data.errorType === 'individual_bill_already_closed') {
          openModal('Conta do usuário já fechada');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }
      }
    }
  }

  async function handleCloseAccount() {
    try {
      await api.put('client/close-table-bill', {
        session_key: sessionKey,
      });

      setLastPixCode('');

      history.push('/app/review');
    } catch (err) {
      if (!err.response.ok) {
        if (err.response.data.errorType === 'table_session_not_found') {
          openModal('Sessão da mesa não encontrada, por favor tente novamente');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }
      }
    }
  }

  async function handleCloseAccountWithData(data) {
    if (requireTableNumber && !data.command_table_number) {
      toast.error("Favor informar a mesa.")

      return false
    }
    try {
      await api.put('client/close-table-bill', {
        session_key: sessionKey,
        command_table_number: data.command_table_number || null
      });

      setLastPixCode('');
      setIsModalInformCommandTableNumberOpened(false)

      history.push('/app/review');
    } catch (err) {
      if (!err.response.ok) {
        if (err.response.data.errorType === 'table_session_not_found') {
          openModal('Sessão da mesa não encontrada, por favor tente novamente');
          setModalAction([
            () => {
              systemErrors();
            },
          ]);
        }
      }
    }
  }

  useEffect(() => {
    async function getTableData() {
      try {
        const res = await api.get('client/payments', {
          params: { session_key: sessionKey },
        });

        setTotalPayments(res.data.total_value);
      } catch (error) {
        if (!error.response?.ok && error.response) {
          switch (error.response.data.errorType) {
            case 'service_unavailable':
              history.push(`/app/systemError`);

              break;
            default:
              console.log(error);
              break;
          }
        } else {
          console.log(error);
        }
      }
    }
    getTableData();
  }, [sessionKey]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get('client/bills', {
          params: { session_key: sessionKey },
        });

        setTableNumber(res.data.session.table.table_number);
      } catch (err) {
        console.log(err);
        if (!err.response.ok) {
          if (err.response.data.errorType === 'no_buyer_bill_found') {
            openModal('Usuário sem conta nesta mesa');
            setModalAction([
              () => {
                systemErrors();
              },
            ]);
          }

          if (err.response.data.errorType === 'closed_table_session') {
            openModal(
              'A comanda da mesa já foi fechada! Leia o qrCode novamente para abrir outra comanda',
            );
            setModalAction([
              () => {
                systemErrors();
              },
            ]);
          }
        }
      }
    }
    if (sessionKey) {
      fetchData();
    }
  }, [sessionKey, history, logOut, systemErrors]);

  const [hasCloseIndividual, setHasCloseIndividual] = useState(false);

  useEffect(() => {
    async function fetchDatas() {
      const res = await api.get('client/table-bill', {
        params: { session_key: sessionKey },
      });

      const dataTable = res.data;
      const manyUsers = dataTable.bills.filter(bill => bill.buyer_id !== null);
      if (manyUsers > 1) {
        setHasCloseIndividual(true);
      }
      setTotalTable([dataTable]);

      setTotalLounge(dataTable.table.price_limit);
    }
    fetchDatas();
  }, [sessionKey]);

  const totalTaxPrice = totalTable
    ? totalTable.reduce((acum, curr) => acum + curr?.total_service_price, 0)
    : 0;

  const totalPrice = totalTable
    ? totalTable.reduce((acum, curr) => acum + curr?.total_price, 0)
    : 0;

  function replaceValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  function handleGoCloseCredit() {
    history.push({
      pathname: '/app/close-table-credit',
      account: 'table',
    });
  }

  const [isModalCopiedPixOpened, setIsModaCopiedPixOnened] = useState(false);

  function toggleModalCopiedPix() {
    setIsModaCopiedPixOnened(!isModalCopiedPixOpened);
  }

  return (
    <Container>
      <Content>
        <Modal
          isOpen={isModalInformCommandTableNumberOpened}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Informe o número da comanda"
          onRequestClose={toggleModalCommandTableNumber}
        >
          <Form onSubmit={handleCloseAccountWithData}>
            <ModalTitle>
              <p>Informe o número da mesa:</p>
            </ModalTitle>
            <Input name="command_table_number" type="text" />
            <ModalFooter>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type='button' title='Cancelar' inverted onClick={toggleModalCommandTableNumber} />
                <Button type='submit' title='Enviar' />
              </div>
            </ModalFooter>
          </Form>
        </Modal>
        <Modal
          isOpen={isModalCopiedPixOpened}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
          onRequestClose={toggleModalCopiedPix}
        >
          <>
            <ModalTitle>
              <p style={{ fontWeight: 'bold' }}>Código copiado com sucesso!</p>
              <p style={{ fontSize: 14 }}>
                Com o cídogo pix copiado, abra a área PIX no app do seu banco,
                cole o código e efetue o pagamento.
              </p>
            </ModalTitle>
            <ModalFooter>
              <ConfirmButtonOutline onClick={() => verifyPix()}>
                {buttonVerifyPix}
              </ConfirmButtonOutline>

              <ConfirmPixButton
                onClick={() => {
                  closeModalPix();
                  setIsOpen(true);
                  setModalButton(true);
                  toggleModalCopiedPix();
                  setButtonVerifyPix('Já efetuei pagamento');
                  setButtonCopyText('Copiar');
                }}
              >
                Gerar novo código Pix online
                <img
                  src={pixIcon}
                  alt="logo do pix"
                  style={{ marginLeft: 8 }}
                />
              </ConfirmPixButton>

              {/* {modalButton.toString() === 'true' && (
                  <CancelButton
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                )} */}
            </ModalFooter>
          </>
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          // onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          {pixCode ? (
            <>
              <ModalTitle>
                <p style={{ fontWeight: 'bold' }}>Gerar novo código Pix</p>
                <p style={{ fontSize: 14 }}>
                  Copie o código PIX abaixo, abra a área PIX no app do seu
                  banco, cole o código e efetue o pagamento.
                </p>
              </ModalTitle>
              <ModalFooter>
                <p style={{ textAlign: 'left' }}>Código Pix Copia e Cola</p>
                <div style={{ display: 'flex' }}>
                  <input value={pixCode}></input>
                  <ConfirmPixButton
                    style={{ marginLeft: 8, marginRight: 0 }}
                    onClick={() => {
                      navigator.clipboard.writeText(pixCode);
                      setButtonCopyText('Copiado!');
                    }}
                  >
                    {buttonCopyText}
                  </ConfirmPixButton>
                </div>

                <PriceText style={{ fontSize: '27px' }}>R${totalPix}</PriceText>
                <p style={{ textAlign: 'left' }}>
                  <strong>
                    O Código expira em 5 minutos! <br />
                    Comunique o pagamento ao garçom
                  </strong>
                </p>

                <ConfirmButtonOutline onClick={() => verifyPix()}>
                  {buttonVerifyPix}
                </ConfirmButtonOutline>
                <CloseButton onClick={() => setPixCode(null)}>
                  Escolher outros métodos
                </CloseButton>
                {modalButton.toString() === 'true' && (
                  <CancelButton
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                )}
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalTitle>
                {!loadingPix && (
                  <p style={{ textAlign: 'left' }}>{modalPhrase}</p>
                )}
              </ModalTitle>
              <ModalFooter>
                {restaurantAcceptsPix.toString() === 'true' && (
                  <>
                    {loadingPix ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <PulseLoader color="#FF2129" />
                        <p>Gerando código Pix...</p>
                      </div>
                    ) : (
                      <>
                        <CloseButtonPix onClick={closeModalPix}>
                          Pix online
                          <img
                            src={pixIcon}
                            alt="logo do pix"
                            style={{ marginLeft: 8 }}
                          />
                        </CloseButtonPix>
                        {hasCloseIndividual && (
                          <CloseButtonPix onClick={closeModalPixIndividual}>
                            A Minha, Individual por Pix{' '}
                            <img
                              src={pixIcon}
                              alt="logo do pix"
                              style={{ marginLeft: 8 }}
                            />
                          </CloseButtonPix>
                        )}
                      </>
                    )}
                  </>
                )}

                {restaurantAcceptsPaytime.toString() === 'true' &&
                  !loadingPix && (
                    <ConfirmCreditButton onClick={handleGoCloseCredit}>
                      Cartão de crédito online
                    </ConfirmCreditButton>
                  )}

                {!loadingPix && (
                  <CloseButton onClick={closeModal} disabled={loadingPix}>
                    {' '}
                    Outros métodos - chamar o garçom
                  </CloseButton>
                )}

                {hasCloseIndividual && (
                  <CloseButton
                    onClick={closeIndividualTable}
                    disabled={loadingPix}
                  >
                    A Minha, Individual
                  </CloseButton>
                )}
                {modalButton.toString() === 'true' && !loadingPix && (
                  <CancelButton
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                )}
              </ModalFooter>
            </>
          )}
        </Modal>
        <ItemListHeader>
          <div>
            <strong>
              {restaurantUseCommand.toString() === 'true'
                ? 'Pedidos da comanda'
                : tableType === 'lounge'
                  ? 'Pedidos no lounge'
                  : 'Pedidos na mesa'}
            </strong>
          </div>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {totalTable &&
              totalTable.map(table => (
                <Orders key={table?.id}>
                  {table?.bills.map(bill => (
                    <React.Fragment key={bill.id}>
                      <MenuItemHeader>
                        <p>
                          {bill.buyer ? (
                            <>
                              <FiSmartphone />
                              {bill.buyer.phone || bill.buyer.name}
                            </>
                          ) : (
                            <>
                              <FiUserMinus />
                              {bill.waiter.name}
                            </>
                          )}
                        </p>
                        {bill.service_tax ? (
                          <p>
                            {formatValue(
                              bill.total_service_price -
                              bill.rescues?.reduce(
                                (acc, cur) => acc + parseFloat(cur.cashback),
                                0,
                              ),
                            )}
                          </p>
                        ) : (
                          <p>
                            {formatValue(
                              bill.total_price -
                              bill.rescues?.reduce(
                                (acc, cur) => acc + parseFloat(cur.cashback),
                                0,
                              ),
                            )}
                          </p>
                        )}
                      </MenuItemHeader>
                      <MenuItemBody key={bill.id}>
                        <ul>
                          {bill.order_baskets.map(basket => (
                            <React.Fragment key={basket.id}>
                              {basket.orders.map(item => (
                                <li key={item.id}>
                                  <div
                                    className={
                                      bill.status === 'finished'
                                        ? 'bill_finished'
                                        : ''
                                    }
                                  >
                                    <p>{formatTime(basket.start_time)}</p>
                                    {item.use_weight ? (
                                      <p>{`${replaceValue(item.weight)} kg`}</p>
                                    ) : (
                                      <p>{`${item.amount} x`}</p>
                                    )}
                                    <p>{item.product.name}</p>
                                  </div>

                                  <p
                                    className={
                                      bill.status === 'finished'
                                        ? 'bill_finished'
                                        : ''
                                    }
                                  >
                                    {formatValue(item.total_price)}
                                  </p>
                                </li>
                              ))}
                            </React.Fragment>
                          ))}
                          {bill.rescues.map(rescue => (
                            <React.Fragment key={rescue.id}>
                              <li key={rescue.id} style={{ color: '#018D66' }}>
                                <div
                                  className={
                                    bill.status === 'finished'
                                      ? 'bill_finished'
                                      : ''
                                  }
                                >
                                  <p>{formatTime(rescue.createdAt)}</p>
                                  <p>Resgate Cashback</p>
                                </div>

                                <p
                                  className={
                                    bill.status === 'finished'
                                      ? 'bill_finished'
                                      : ''
                                  }
                                >
                                  - {formatValue(rescue.cashback)}
                                </p>
                              </li>
                            </React.Fragment>
                          ))}
                          <ServiceTax>
                            <div
                              className={
                                bill.status === 'finished'
                                  ? 'bill_finished'
                                  : ''
                              }
                            >
                              {bill.service_tax ? (
                                <>
                                  <p>
                                    Serviço {restaurantTaxServiceValue}%
                                    (Opcional)
                                  </p>
                                  <p>
                                    {formatValue(
                                      bill.total_service_price -
                                      bill.total_price,
                                    )}
                                  </p>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </ServiceTax>
                        </ul>
                      </MenuItemBody>
                    </React.Fragment>
                  ))}
                </Orders>
              ))}
          </MenuItem>
        </MenuContent>

        <Footer>
          <AreaFinalPrice>
            {tableType !== 'lounge' ? (
              <DescriptionText>
                Você está solicitando o fechamento da conta da{' '}
                {restaurantUseCommand.toString() === 'true'
                  ? 'comanda'
                  : 'mesa'}
                .
                <br /> O total parcial no momento é de:
              </DescriptionText>
            ) : (
              <DescriptionText>
                Esse lounge tem um saldo total de R$
                {parseFloat(totalLounge)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                .
                <br />O total consumido no momento é de:
              </DescriptionText>
            )}

            {restaurantTaxService.toString() === 'true' ? (
              <PriceText>
                {formatValue(totalTaxPrice - tablePayments)}
              </PriceText>
            ) : (
              <PriceText>{formatValue(totalPrice - tablePayments)}</PriceText>
            )}

            {tableType === 'lounge' && (
              <DescriptionText>
                Saldo restante:{' '}
                <strong>
                  {formatValue(parseFloat(totalLounge || 0) - totalPrice)}
                </strong>
              </DescriptionText>
            )}
          </AreaFinalPrice>

          {tableType !== 'lounge' && (
            <AreaButtons>
              <CloseButton
                style={{ marginBottom: 0 }}
                type="button"
                onClick={() => {
                  openModal('Qual a forma de pagamento?');
                  setModalButton(true);
                  setModalAction([
                    () => {
                      handleCloseAccount();
                    },
                  ]);
                }}
              >
                Fechar a conta
              </CloseButton>

              {restaurantAcceptsPix &&
                (lastPixCode !== null && lastPixCode !== '' ? (
                  <CloseButtonPix
                    onClick={() => {
                      navigator.clipboard.writeText(lastPixCode);
                      setButtonCopyTextFooter('Código Copiado!');
                      toggleModalCopiedPix();
                      setButtonVerifyPix('Já efetuei o pagamento');
                    }}
                  >
                    Copiar Pix online novamente
                    <img
                      src={pixIcon}
                      alt="logo do pix"
                      style={{ marginLeft: 8 }}
                    />
                  </CloseButtonPix>
                ) : (
                  <CloseButtonPix
                    type="button"
                    onClick={() => {
                      closeModalPix();
                      setIsOpen(true);
                      setModalButton(true);
                    }}
                  >
                    {loadingPix
                      ? 'Gerando código Pix...'
                      : 'Fechar conta com Pix online'}
                  </CloseButtonPix>
                ))}
            </AreaButtons>
          )}
        </Footer>
      </Content>
    </Container>
  );
};

export default TableAccount;
