import React from 'react';
import { DefaultSelect } from 'ui-kit-takeat';
import { SelectContainer } from './styles';
import { ErrorSpan } from '../CustomInput/styles';

export const CustomSelect = ({ ...rest }) => {
  const { error } = rest;

  return (
    <SelectContainer>
      <DefaultSelect {...rest} />
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </SelectContainer>
  );
};
