import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
`;
export const BorderImg = styled.div`
  width: 37px;
  height: 37px;
  margin-left: 8px;
  border-radius: 100%;
  border: 1px solid transparent;

  ${props =>
    props.active &&
    css`
      border: 1px #ff2c3a solid;
    `}
  img {
    width: 35px;
    height: 35px;
  }
`;
export const FlagImg = styled.img`
  border-radius: 100%;
  border: 2px #fff solid;
`;
