import React, { useContext } from 'react';
import { Header } from '../../components/ClubRegisterComponents/Header';
import { PersonalData } from '../../components/ClubRegisterComponents/Steps/PersonalData';
import { Account } from '../../components/ClubRegisterComponents/Steps/Account';
import { Address } from '../../components/ClubRegisterComponents/Steps/Address';
import { Confirmation } from '../../components/ClubRegisterComponents/Steps/Confirmation';
import { PageContainer } from './styles';
import FormCard from '../../components/ClubRegisterComponents/FormCard';
import { ClubFormContext } from '../../context/clubForm';
import ModalInfo from '../../components/ClubRegisterComponents/ModalInfo';
import celebrationImg from '../../assets/images/Celebration.svg';
import phoneErrorImg from '../../assets/images/PhoneError.svg';
import { Button } from 'ui-kit-takeat';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../context/auth';

export const ClubRegister = () => {
  const {
    formStep,
    modalConfirmationOpen,
    modalErrorOpen,
    toggleModalError,
  } = useContext(ClubFormContext);
  const history = useHistory();
  const { restaurantId, clube } = useAuth();

  const stepsComponents = [
    <Confirmation />,
    <PersonalData />,
    <Account />,
    <Address />,
  ];

  return (
    <PageContainer>
      <Header />

      <FormCard>{stepsComponents[formStep]}</FormCard>

      <ModalInfo
        isOpen={modalConfirmationOpen}
        // toggle={toggleModalConfirmation}
      >
        <img src={celebrationImg} alt="Icone de comemoração" />
        <ModalInfo.Body>
          <span>Cadastro realizado com sucesso!</span>
          <p>
            Agora você ganha <b>{clube.settings.percent}% de cashback</b> nas
            suas compras! 💸✨
          </p>
        </ModalInfo.Body>
        <Button
          buttonColor="#2ec9b7"
          title="Voltar ao cardápio"
          onClick={() => history.push(`/menu/${restaurantId}`)}
        />
      </ModalInfo>

      <ModalInfo isOpen={modalErrorOpen}>
        <img src={phoneErrorImg} alt="Icone de celular com erro" />
        <ModalInfo.Body>
          <span>
            O número informado ao fazer os pedidos deve ser o mesmo para o
            cadastro no clube
          </span>
        </ModalInfo.Body>
        <Button
          inverted
          title="Entendi"
          onClick={() => {
            toggleModalError();
            history.push(`/menu/${restaurantId}`);
          }}
        />
      </ModalInfo>
    </PageContainer>
  );
};
