import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 55px;

  a {
    text-decoration: none;
    color: var(--primary);
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 900;
  }

  p {
    margin-top: 20px;
    text-align: center;
  }

  img {
    width: 180px;
  }

  textarea {
    border: 1px solid #33333310;
    border-radius: 7px;
    width: 80%;
    text-align: center;
    padding: 15px;
  }
`;

export const Observations = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 24px;

  li {
    background: var(--primary);
    padding: 10px 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    font-size: 12px;
  }

  li.selected {
    background: ${shade(0.2, '#ff2c3a')};
  }
`;

export const Rating = styled.div``;
export const ButtonRating = styled.button``;
