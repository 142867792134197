import React from 'react';
import { StoreInfoContainer } from './styles';
import { useAuth } from '../../../context/auth';
import imgPlaceholder from '../../../assets/images/ImgPlaceholder.png';

export const StoreInfo = () => {
  const { clube } = useAuth();

  return (
    <StoreInfoContainer>
      <img src={clube.avatar?.url || imgPlaceholder} />
      <span>Clube {clube.name}</span>
    </StoreInfoContainer>
  );
};
