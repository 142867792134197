import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiArrowLeft, FiUser, FiUsers } from 'react-icons/fi';
import { useAuth } from '../../context/auth';

import IndividualAccount from '../IndividualAccount';
import TableAccount from '../TableAccount';

import { Container, Content, HeaderAction, RouterDomLink, Nav } from './styles';

const CloseAccount = () => {
  const { restaurantId, restaurantUseCommand, tableType } = useAuth();

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>{tableType !== 'prepaid' ? 'Fechar Conta' : 'Pedidos'}</h1>
            <RouterDomLink to={`/menu/${restaurantId}`}>
              <FiArrowLeft size={18} color="#FE1933" />
              {' '}
              <span style={{ color: '#FE1933' }}>Voltar</span>
            </RouterDomLink>
          </header>
        </HeaderAction>

        <Tabs>
          <Nav>
            <TabList>
              {tableType !== 'prepaid' && tableType !== 'lounge' && (
                <Tab>
                  <FiUsers color="#FE1933" />
                  {restaurantUseCommand.toString() === 'true' ? (
                    <span style={{ color: '#FE1933' }}>Comanda</span>
                  ) : (
                    <span style={{ color: '#FE1933' }}>Mesa</span>
                  )}
                </Tab>
              )}

              {tableType !== 'lounge' && (
                <Tab>
                  <FiUser color="#FE1933" />
                  <span style={{ color: '#FE1933' }}>Individual</span>
                </Tab>
              )}

              {tableType === 'lounge' && (
                <Tab>
                  <FiUser color="#FE1933" />
                  <span style={{ color: '#FE1933' }}>Lounge</span>
                </Tab>
              )}
            </TabList>
          </Nav>

          {tableType !== 'prepaid' && (
            <TabPanel>
              <TableAccount />
            </TabPanel>
          )}

          <TabPanel>
            <IndividualAccount />
          </TabPanel>
        </Tabs>
      </Content>
    </Container>
  );
};

export default CloseAccount;
