import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled.div`
  width: 340px;
  position: fixed;
  top: 0;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: #fff;
  border-bottom: 1px solid #f8f8f8;

  input {
    width: 340px;
    height: 48px;
    color: var(--primary);

    background: transparent;
    border-radius: 7px;
    border: 1px solid var(--primary);
    margin-top: 40px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;

    &::placeholder {
      text-align: center;
      color: #ff2c3acc;

      margin-top: 40px;
    }
  }
`;

export const Content = styled.div`
  width: 360px;
  max-width: 1000px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
  margin-top: 65px;
  background: #fff;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: var(--primary);
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  width: 340px;
  background: #fff;
  border-radius: 7px;
  border-radius: 7px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 15px;
  width: 100%;
`;

export const ItemListHeader = styled.div`
  color: #333;
  margin-top: 200px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const Footer = styled.footer`
  width: 340px;
  height: 65px;
  background: #fff;
  position: fixed;
  bottom: 0;

  div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      width: 100%;
      height: 48px;
      background: var(--primary);
      color: #fff;
      border-radius: 8px 8px 0 0;
      border: none;
      position: fixed;
      bottom: 0px;
      font-weight: 500;
    }
  }
`;

export const RouterDomLink = styled(Link)`
  text-decoration: none;
  color: ${shade(0.1, '#333333')};
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const ButtonDeletePhone = styled.button`
  width: 100px;
  height: 30px;
  border: 1px solid var(--primary);
  background: var(--primary);
  border-radius: 7px;
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
`;

export const UserDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const CartDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding-top: 20px;

  & + div {
    margin-top: 20px;
    border-top: 1px solid #33333320;
  }
`;

export const MenuItemImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 7px;
    margin-right: 15px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 490px;

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
    }
  }

  p {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const MenuItemComplements = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    color: var(--primary);
    font-size: 10px;
    padding: 3px 5px;
    margin-right: 5px;
    border: 1px solid var(--primary);
    border-radius: 14px;
  }
`;

export const MenuItemQuantity = styled.div`
  display: flex;
  width: 150px;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 10px;
    cursor: pointer;
  }

  p {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--primary);
  }
`;

export const MakingOrder = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--primary);
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  }

  img {
    width: 92px;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }
`;
