import React from 'react';
import { BannerContainer } from './styles';

const CashbackBanner = ({ onClick, children }) => {
  return (
    <BannerContainer
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </BannerContainer>
  );
};

CashbackBanner.Body = ({ children }) => (
  <div
    style={{
      minHeight: 74,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 4,
    }}
  >
    {children}
  </div>
);

export default CashbackBanner;
